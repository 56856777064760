import React from 'react';
import './WashLaPage.css';


function WashLaPage() {

    const washla_demo1 = require('../images/WashLa-05.png'); 
    const washla_demo2 = require('../images/WashLa-06.png');  
    const washla_bg1 = require('../images/WashLa-07.png'); 
    const washla_feature1 = require('../images/WashLa-02.png'); 
    const washla_feature2 = require('../images/WashLa-03.png');
    const washla_feature3 = require('../images/WashLa-04.png');
    const washla_review1 = require('../images/BG-39.png');
    const washla_review2 = require('../images/BG-39.png');
    const washla_review3 = require('../images/BG-39.png');
    // const washla_review3 = require('../images/UserReview-01.png');

    return (
        <div className='wrap-washla'>

            <div className='washla-hero-container'>
                <div className='washla-hero-content'>
                    <div className='washla-hero-item-1'>
                        <h1>Wash La!</h1>
                        <p>Wash La! is a leading one-stop shoe wash & sneaker cleaning service provider with door-to-door pick-up and delivery service anywhere within Klang Valley. With their latest <strong>mobile app (available on iOS and Android)</strong>, you can just sit back, relax and book a pick-up and delivery from the comfort of your home, at your choice of place and time.</p>
                    </div>
                    <div className='washla-hero-item-2'>
                        <img src={washla_demo1} alt='WashLa! Project' />
                    </div>
                </div>
            </div>
            
            <div className='washla-body-container'>
                <div className='washla-body-content-1'>
                    
                    {/* <div className='washla-body-item-1'>
                        <div className='washla-body-itemcard'>
                            <h2>Idea</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='washla-body-itemcard'>
                            <h2>Challenge</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='washla-body-itemcard'>
                            <h2>Results</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                    </div> */}
                    <div className='washla-body-item-1'>
                        <div className='washla-body-itemcard'>
                            <h2>App Store <br/>Deployment</h2>
                            <p>Our professional mobile application developers will assist you to deploy your mobile application smoothly in both Google Play store & Apple App Store.</p>
                        </div>
                        <div className='washla-body-itemcard'>
                            <h2>Support and <br/>Maintenance</h2>
                            <p>We also offer maintenance and support services that ensure your business runs smoothly by providing the necessary repairs, updates, and troubleshooting.</p>
                        </div>
                    </div> 
                    <div className='washla-body-item-2'>
                        <img src={washla_demo2} alt='WashLa! Project' />
                    </div>

                </div>

                <div className='washla-body-content-2'>
                    <img src={washla_bg1} alt='WashLa! Project' />
                </div>

                <div className='washla-body-content-3'> 
                    <div className='washla-design-header'>
                        <h2>Design</h2>
                        <p>With this project, we've put emphasis on functionality and the utility of the app. The interface should not be distracting yet still pleasing to the eye. </p>
                    </div>

                    <div className='washla-design-features'>
                        <h2>Some Awesome Features</h2>

                        <div className='washla-feature-content'>
                            <div className='washla-feature-item'>
                                <img src={washla_feature1} alt='WashLa! Project' />
                                <h4>User Interface & User Experience</h4>
                                <p>We work on eye-catching design and a friendly user experience are the keys to keeping your target audience in your mobile app. We have in-house professional UI/UX designers to make sure your mobile app works well.</p>
                            </div>
                            <div className='washla-feature-item'>
                                <img src={washla_feature2} alt='WashLa! Project' />
                                <h4>Hybrid Platform</h4>
                                <p>Save cost, build Android and iOS mobile application at once! Our hybrid development enables the mobile application to work well.</p>
                            </div>
                            <div className='washla-feature-item'>
                                <img src={washla_feature3} alt='WashLa! Project' />
                                <h4>Google Play Store & Apple App Store Deployment</h4>
                                <p>Our professional mobile application developers will assist you to deploy your mobile application smoothly in both Google Play Store & Apple App Store.</p>
                            </div>
                        </div>
                    </div>

                    <div className='washla-reviews' >
                        <h2>User Reviews</h2>

                        <div className='washla-reviews-content'>
                            {/* <div className='washla-reviews-item'>
                                <img src={washla_review1} alt='WashLa! Project' />
                                <h4>Happiness is a freshly cleaned house and stuff</h4>
                                <p>“Very happy with their door to door service coz the deliver very kind, he will update his location to me first once he pick up or send item to my place.</p>
                                <p>I like my sneaker after cleaned, it looks like a new pair of shoes!!”</p>
                            </div> */}
                            <div className='washla-reviews-item'>
                                <img src={washla_review2} alt='WashLa! Project' />
                                <h4>Convenient to use</h4>
                                <p>“I like the way after done payment, I will receive an email which show the item and details of what I bought from your service. For me is also a convenient app especially for us who are working from home and don’t have enough of time to clean their clothes and sneakers.”</p>
                            </div>
                            <div className='washla-reviews-item'>
                            {/* className='washla-review-img-3' */}
                                <img src={washla_review3} alt='WashLa! Project' />
                                <h4>Good features</h4>
                                <p>“All features are good. Thank for making it. I like the Wash La! Prepared bag to keep my sneaker. Driver also very kind, will contact me when on the way my place.”</p>
                            </div>
                        </div>
                    </div>

                    <div className='washla-downloadapp' >
                        <h2>App Download</h2>

                        <div className='washla-downloadapp-content'>
                            <div className='washla-downloadapp-item'>
                                <p>This application is now available for download! Experience these all these features from now!</p>
                            </div>
                            <div className='washla-downloadapp-btn'>
                                <a href='https://apps.apple.com/my/app/wash-la/id1582400036' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe902;" className='download-icon-1'></span>
                                        <p>iOS Download</p>
                                    </button>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.washla.my' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe901;" className='download-icon-1'></span>
                                        <p>Android Download</p>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default WashLaPage;