import React from "react";
// import ReactDOM from "react-dom";
// import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import ContactSection from './ContactSection';
import Footer from './Footer';

import './PortfolioTesting.css';
import './portfolio-sass.scss';

const ourport_img1 = require('../images/BG-02.png');
const ourport_img2 = require('../images/TCLam-02.png');
const ourport_img3 = require('../images/WashLa-05.png');
const ourport_img4 = require('../images/AKC-01.png');
const ourport_img5 = require('../images/UDBio-01.png');

const urban_logo = require('../images/Urban-00.png');
const tclam_logo = require('../images/TCLam-07.png');
const washla_logo = require('../images/WashLa-08.png');
const akc_logo = require('../images/AKC-00.png');
const udbio_logo = require('../images/UDBio-00.png');


class MySection extends React.Component {
    render() {
        return (
            <div className="section">
                <h3>{this.props.content}</h3>
            </div>
        );
    }
}


class Urban extends React.Component {

    render() {
        return (
            <div className="section" >

                <div className="fullpage-container">
                    <div className="fullpage-img-cont-1">
                        <img
                            className="fullpage-img-1"
                            src={ourport_img1}
                            alt="Urban Wallstreet"
                        />
                    </div>

                    <div className="fullpage-desc-1 fp-urban">
                        <img src={urban_logo} className="proj-logo-01" alt="Urban Wallstreet" />
                        <h3>Urban Wallstreet</h3>
                        <p>Urban Wallstreet is an online collectable trading marketplace that aims to smoothen every step of your transaction, whether you are a buyer or a seller! Shop the latest and greatest in streetwear at UR and bid for your favourite on-trend items!</p>
                        
                        <a href="/our-portfolio/urban-wall-street"  className='mb-port-btn-1' target='_blank' rel="noreferrer noopener">View Project</a>
                        
                        <a href="/our-portfolio/urban-wall-street" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="gooey-button">
                                View Project
                                <span class="urban-1">
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

class Tclam extends React.Component {

    render() {
        return (
            <div className="section">

                <div className="fullpage-container">
                    <div className="fullpage-img-cont-2">
                            <img
                                className="fullpage-img-2"
                                src={ourport_img2}
                                alt="TCLam Fruit Shop"
                            />
                    </div>

                    <div className="fullpage-desc-1 fp-tclam">
                        <img src={tclam_logo} className="proj-logo-01" alt="TC Lam Fruit Shop" />
                        <h3>TC Lam Fruit Shop</h3>
                        <p>With over 5 years of experience in supplying top quality imported fruits, TC Lam Fruit Shop got you covered for that craving while you sit back and relax and just order through TC Lam Fruit Shop's mobile app available on Apple AppStore and Google PlayStore.</p>
                    
                        <a href="/our-portfolio/tclamshop"  className='mb-port-btn-2' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="/our-portfolio/tclamshop" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="tclam-button">
                                View Project
                                <span class="tclam-1">
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

class Washla extends React.Component {

    render() {
        return (
            <div className="section">

                <div className="fullpage-container">
                    <div className="fullpage-img-cont-3">
                            <img
                                className="fullpage-img-3"
                                src={ourport_img3}
                                alt="WashLa! Shoe Washing Service"
                            />
                    </div>

                    <div className="fullpage-desc-1 fp-washla">
                        <img src={washla_logo} className="proj-logo-01" alt="Wash La" />
                        <h3>Wash La!</h3>
                        <p>Wash La! is a leading one-stop shoe wash & sneaker cleaning service provider with door-to-door pick-up and delivery service anywhere within Klang Valley. With their latest mobile app (available on iOS and Android), you can just sit back, relax and book a pick-up and delivery from the comfort of your home, at your choice of place and time.</p>
                    
                        <a href="/our-portfolio/wash-la"  className='mb-port-btn-3' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="/our-portfolio/wash-la" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="washla-button">
                                View Project
                                <span class="washla-1">
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

class AKC extends React.Component {

    render() {
        return (
            <div className="section">

                <div className="fullpage-container">
                    <div className="fullpage-img-cont-4">
                            <img
                                className="fullpage-img-2"
                                src={ourport_img4}
                                alt="Ape Kids Club AKC"
                            />
                    </div>

                    <div className="fullpage-desc-1 fp-akc">
                        <img src={akc_logo} className="proj-logo-02" alt="AKC Ape Kids Club" />
                        <h3>Ape Kids Club Website</h3>
                        <p>Ape Kids Club is a collection of 9,999 Kids Apes that were sprouted from our thousand year old magical tree. Victorior, the beloved and talented art teacher, will take care of all Kid Apes in the our Club with many planned activities and surprises.</p>
                    
                        <a href="https://www.apekidsclub.io/"  className='mb-port-btn-3' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="https://www.apekidsclub.io/" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="akc-button">
                                View Project
                                <span class="akc-1">
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

class UDBio extends React.Component {

    render() {
        return (
            <div className="section">

                <div className="fullpage-container">
                    <div className="fullpage-img-cont-4">
                            <img
                                className="fullpage-img-2"
                                src={ourport_img5}
                                alt="UD-Bio Test Kit Malaysia"
                            />
                    </div>

                    <div className="fullpage-desc-1 fp-udbio">
                        <img src={udbio_logo} className="proj-logo-01" alt="UD-Bio Test Kit Malaysia" />
                        <h3>UD-Bio Website</h3>
                        <p>The UD-Bio Biotec saliva antigen test kit is developed and manufactured by the Shenzhen Ultra-Diagnostics Biotec. Co., Ltd. (hereinafter referred to as UD-Bio) and was established in 2016. Dedicates in the comprehensive product line of Covid-19 Antigen Test Kit & Rapid Saliva Test Kit In Malaysia.</p>
                    
                        <a href="https://ud-bio.com.my/"  className='mb-port-btn-3' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="https://ud-bio.com.my/" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="udbio-button">
                                View Project
                                <span class="udbio-1">
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}






// const anchors = ["urban", "tclam", "washla", "thirdPage", "contact"];

const FullpageWrapper = () => (
    <ReactFullpage
        // anchors={anchors}
        navigation
        // navigationTooltips={anchors}
        sectionsColor={['#fec30b50', "#01b44f20", "#f2ca0029", "#7bc1ff70", "#7DBA0020"]}
        onLeave={(origin, destination, direction) => {
        console.log("onLeave event", { origin, destination, direction });
        }}
        render={({ state, fullpageApi }) => {
        console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

            return (
                <>
                    <Urban />
                    <Tclam />
                    <Washla />
                    <AKC/>
                    <UDBio />
                    <MySection content={<><ContactSection /><Footer /></>}/>
                </>

                    
            );
        }}
    />
);

export default FullpageWrapper;