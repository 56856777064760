import React from 'react';
import './Services.css';


function TypeOfServices(props) {
    return (
        <>
            <div className='typeofserv-items reveal'> 
                <img className='typeofserv-item-img'
                    alt='BaseTwo Type of Services'
                    src={props.typeofserv_img}
                />
                <h4>{props.typeofserv_title}</h4>
                <p>{props.typeofserv_cate}</p> 
            </div>
        </>
    );
}

function Services() {

    const servtype_1 = require('../images/BG-05.png');
    const servtype_2 = require('../images/BG-06.png');
    const servtype_3 = require('../images/BG-07.png');
    const servtype_4 = require('../images/BG-08.png');
    const servtype_5 = require('../images/BG-09.png');
    const servtype_6 = require('../images/BG-10.png');
    const servtype_7 = require('../images/BG-21.png');
    const servtype_8 = require('../images/BG-22.png');
    const servtype_9 = require('../images/BG-23.png');
    const servprocess_1 = require('../images/BG-24.png');
    const servprocess_2 = require('../images/BG-25.png');
    const servprocess_3 = require('../images/BG-26.png');
    const servprocess_4 = require('../images/BG-27.png');
    const servprocess_5 = require('../images/BG-28.png');
    const servprocess_6 = require('../images/BG-29.png');
    const servindus_1 = require('../images/BG-30.png');
    const servindus_2 = require('../images/BG-31.png');
    const servindus_3 = require('../images/BG-32.png');
    const servindus_4 = require('../images/BG-33.png');
    const servindus_5 = require('../images/BG-34.png');
    const servindus_6 = require('../images/BG-35.png');

    return (
        
        <div className='wrap-services-page'>

            <div className='services-hero-container'> 
                <div className='serv-hero-content'>
                    <h2>Design and Development Services</h2>
                    <p>We offer wide range of services for all kinds of businesses. We are ready to assist you on every stage and make sure you recieve the best services ever.</p>

                </div>
            </div>

            <div className='services-body-container-1'>
                <div className='serv-type-container'>
                    <TypeOfServices
                        typeofserv_img={servtype_1}
                        typeofserv_title='iPhone'
                        typeofserv_cate='Application Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_2}
                        typeofserv_title='Android'
                        typeofserv_cate='Application Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_3}
                        typeofserv_title='iOS'
                        typeofserv_cate='Application Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_4}
                        typeofserv_title='Cross-Platform'
                        typeofserv_cate='Mobile App Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_5}
                        typeofserv_title='Website'
                        typeofserv_cate='Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_6}
                        typeofserv_title='Web'
                        typeofserv_cate='Application Development'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_7}
                        typeofserv_title='UI/UX'
                        typeofserv_cate='Design Service'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_8}
                        typeofserv_title='iOS'
                        typeofserv_cate='App Design'
                    />
                    <TypeOfServices
                        typeofserv_img={servtype_9}
                        typeofserv_title='Mobile App'
                        typeofserv_cate='Consulting'
                    />
                </div>
            </div>

            <div className='services-body-container-2 '>
                <div className='serv-process-container reveal'>
                    <h2>Our Process</h2>
                    <p>Even the most groundbreaking app ideas need careful planning and strategy implementation. Together with you we’ll jump into your industry, brainstorm and mind map your idea to develop it into a one of a kind app.</p>
                
                    <div className='serv-process-map '>
                        <div className='serv-process-mapitem'>
                            <img src={servprocess_1} alt='BaseTwo Services'/>
                            <p>Idea</p>
                        </div>
                        <div className='serv-process-mapitem '>
                            <img src={servprocess_2} alt='BaseTwo Services'/>
                            <p>Wireframes</p>
                        </div>
                        <div className='serv-process-mapitem '>
                            <img src={servprocess_3} alt='BaseTwo Services'/>
                            <p>Design</p>
                        </div>
                        <div className='serv-process-mapitem '>
                            <img src={servprocess_4} alt='BaseTwo Services'/>
                            <p>Development</p>
                        </div>
                        <div className='serv-process-mapitem '>
                            <img src={servprocess_5} alt='BaseTwo Services'/>
                            <p>Testing</p>
                        </div>
                        <div className='serv-process-mapitem '>
                            <img src={servprocess_6} alt='BaseTwo Services'/>
                            <p>Launch</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='services-body-container-3 '>
                <div className='serv-indus-container reveal'>
                    <h2>Industries We Serve</h2>
                    <p>We create incredible apps for both: small startups and giant corporation - so if you have a great idea and you’re passionate about it, we’ll be happy to help you build the app of your dreams.</p>
                
                    <div className='serv-indus-cards'>
                        <div className='serv-indus-item '>
                            <img src={servindus_1} alt='BaseTwo Services'/>
                            <p>Transport & Logistics </p>
                        </div>
                        <div className='serv-indus-item '>
                            <img src={servindus_2} alt='BaseTwo Services'/>
                            <p>Enterprise Development </p>
                        </div>
                        <div className='serv-indus-item '>
                            <img src={servindus_3} alt='BaseTwo Services'/>
                            <p>Educational & Training </p>
                        </div>
                        <div className='serv-indus-item '>
                            <img src={servindus_4} alt='BaseTwo Services'/>
                            <p>Custom Apps for Marketing </p>
                        </div>
                        <div className='serv-indus-item '>
                            <img src={servindus_5} alt='BaseTwo Services'/>
                            <p>MVP for Startups </p>
                        </div>
                        <div className='serv-indus-item '>
                            <img src={servindus_6} alt='BaseTwo Services'/>
                            <p>Entertainment Apps </p>
                        </div>
                    </div>
                </div>
            </div> 
            
        </div>
    );
}

export default Services;