import React from 'react';
import './ContactSection.css';


function ContactSection() {

    const mobile_bg = require('../images/BG-11-cropped.jpg');


    return (
        
        <div className='wrap-contactus' id='contactus'>
            <div>
                <img src={mobile_bg} className='mobileview-bg-contactus' alt="Base Two Sdn Bhd"/>
            </div>
            <div className='contactus-container'>
                <div className='contactus-content-1'>
                    <h2>Make Dream a Reality</h2>
                    <p>At BaseTwo, we don’t just develop apps. We build dreams. Let’s build yours!</p>
                </div>

                <div className='contactus-content-2'>
                    <form className='contactus-form-content'>
                        <input className='contactus-form-1' type="text" id="name" name="name" placeholder="Name "  /> 
                        <input className='contactus-form-1' type="phone" id="phoneno" name="phoneno" placeholder="Phone No."  /> <br/>
                        <input className='contactus-form-2' type="text" id="email" name="email" placeholder="Email "  /> <br/>
                        <textarea className='contactus-form-2' type="text" id="messages" name="messages" placeholder="Messages "  /> <br/>

                        <input className='contactus-form-submit' type="submit" id="submitbtn" value="Submit" />
                    </form>
                </div>
                
            </div>

            
        </div>
    );
}

export default ContactSection;