import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* Switch = Routes in react-router-dom v6 */
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import PortfolioPage from './components/pages/PortfolioPage';
import ServicesPage from './components/pages/ServicesPage';
import AboutUs from './components/pages/AboutUs';
import NotFoundPage from './components/pages/NotFoundPage';
import Footer from './components/Footer';

import UrbanWall from './components/pages/UrbanWall';
// import MegaMall from './components/pages/MegaMall';
// import KWSPMalaysia from './components/pages/KWSPMalaysia';
import WashLa from './components/pages/WashLa';
import TCLamShop from './components/pages/TCLamShop';

import 'bootstrap/dist/css/bootstrap.min.css';

// import AnimatedCursor from 'react-animated-cursor';


function App() {
  return (
    <>
    <Router>

      <Navbar />  

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-portfolio" element={<PortfolioPage />} />
        <Route path="/our-services" element={<ServicesPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        
        <Route path="/our-portfolio/urban-wall-street" element={<UrbanWall />} />
        <Route path="/our-portfolio/wash-la" element={<WashLa />} />
        <Route path="/our-portfolio/tclamshop" element={<TCLamShop />} />

        {/* <Route path="/our-portfolio/megamall-shopping" element={<MegaMall />} />
        <Route path="/our-portfolio/kwsp-malaysia" element={<KWSPMalaysia />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Footer />
      
    </Router>

    {/* <div>
      <AnimatedCursor
          innerSize={12}
          outerSize={12}
          color='171, 41, 91'
          outerAlpha={0.2}
          innerScale={1}
          outerScale={7}
        />
    </div> */}
    
    </>
  );
}

export default App;
