import React from 'react';
import './AboutPage.css';


function AboutB2() {

    const companylogo = require('../images/logo-color.png');
    const specialties_1 = require('../images/BG-05.png');
    const specialties_2 = require('../images/BG-06.png');
    const specialties_3 = require('../images/BG-07.png');
    const specialties_4 = require('../images/BG-08.png');
    const specialties_5 = require('../images/BG-09.png');
    const specialties_6 = require('../images/BG-10.png');
    const specialties_7 = require('../images/BG-22.png');
    const specialties_8 = require('../images/BG-21.png');
    const specialties_9 = require('../images/BG-23.png');

    return (
        <div className='wrap-aboutus'>

            <div className='aboutus-hero-container'>
                <div className='aboutus-hero-1'>
                    <h1>About BaseTwo</h1>
                    <p>Creating successful apps is what we live for and what we absolutely love doing. Scroll down to learn more about our values and the team that will work on your project.</p>
                </div>
            </div>

            <div className='aboutus-body-container'>
                <div className='aboutus-content-1'>
                    <div className='aboutus-content-item-1'>
                        <h2>Import Silicon Valley to the region, not just the zip code, but the mindset.</h2>
                    </div>

                    <div className='aboutus-content-item-2'>
                        <div className='aboutus-v-m'>
                            <h4>Vision</h4>
                            <p>Improve the user experience of the world by guiding clients through impactful digital transformations. </p>
                        </div>
                        <div className='aboutus-v-m'>
                            <h4>Mission</h4>
                            <p>Create value for our clients by creating value for their customers. </p>
                        </div>
                    </div>

                    <div className='aboutus-content-item-3 reveal'>
                        <img src={companylogo} alt='BaseTwo'/>
                        <h2>Company Profile</h2>
                        <p>Base Two is a full-service app development company that provides app development, design and consulting services for those who want to be successful. Based in Kuala Lumpur, we are a unique blend of creative designers, tech savvy developers and awesome project managers. By designing & developing innovative apps, and marketing the hell out of them, we create the greatest appreneurs the world has ever seen. Even the most groundbreaking ideas need careful planning and strategy implementation. Together with you we’ll jump into your industry, brainstorm, and mind map your idea to develop it into a one of a kind product.</p>
                    </div>
                
                </div>

                <div className='aboutus-content-2'>
                    <div className='aboutus-specialties-1 reveal'>
                        <h2>We are builders.</h2>
                        <p>First and foremost, we are makers — designers, architects and engineers building digital products, businesses and brands.</p>
                    </div>

                    <div className='aboutus-specialties-2 reveal'>
                        <h4>Our Specialties</h4>
                        <p>Specialties: Android & iPhone app design and development, app store optimization, app marketing, app maintenance, and app design too!</p>
                        <div className='aboutus-specialties-logos'>
                            <img src={specialties_1} alt='BaseTwo Specialties'/>
                            <img src={specialties_2} alt='BaseTwo Specialties'/>
                            <img src={specialties_3} alt='BaseTwo Specialties'/>
                            <img src={specialties_4} alt='BaseTwo Specialties'/>
                            <img src={specialties_5} alt='BaseTwo Specialties'/>
                            <img src={specialties_6} alt='BaseTwo Specialties'/>
                            <img src={specialties_7} alt='BaseTwo Specialties'/>
                            <img src={specialties_8} alt='BaseTwo Specialties'/>
                            <img src={specialties_9} alt='BaseTwo Specialties'/>
                        </div>
                        <a href='/our-services'><button>View Our Services</button> </a>
                    </div>
                
                </div>
                
            </div>

        </div>
    );
}

export default AboutB2;