import React, { useEffect } from 'react';
import '../../App.css';
import WashLaPage from '../WashLaPage';
import MetaTags from './MetaTagsComponent';

function WashLa() {

    useEffect(() => {
        document.title = "Wash La! | BaseTwo";  
    }, []);

    return (
        <> 
            <WashLaPage />
            
            <MetaTags
                canonical={`https://base2.my/our-services`} 
                ogurl={`https://base2.my/our-services`} 

                // max 10-15 keywords
                keywords={`Base 2, Base2, Base Two, BaseTwo Sdn Bhd, Base Two Sdn Bhd, Base2 Sdn Bhd, Mobile App Developer, Software Developer, Kuala Lumpur, Klang Valley`
                }
            />
        </>
    ); 
}

export default WashLa;