import React from 'react';
import './Footer.css';


function Footer() {

    const b2_footerlogo = require('../images/logo-color.png');

    return (
        <div className='wrap-footer'>

            <div className='footer-container'>
                <div className='f-container-1'>
                    <img src={b2_footerlogo} alt="Base Two Sdn Bhd"/>
                </div>

                <div className='f-container-2'>
                    <div className='f-contactus'>
                        <a className='f-contactus-no' href='tel:+60392868080'>+603 9286 8080</a>
                        <a className='f-contactus-email'href='mailto:info@base2.my'>info@base2.my</a>
                    </div>
                    <div className='f-copyright'>
                        <p>© {new Date().getFullYear()} . Base Two . All Rights Reserved</p>
                    </div>
                </div>

                <div className='f-container-3'>
                    <span aria-hidden="true" data-icon="&#xe906;" className='f-contact-icon-1'></span>
                    <span aria-hidden="true" data-icon="&#xe905;" className='f-contact-icon-1'></span>
                    <span aria-hidden="true" data-icon="&#xe904;" className='f-contact-icon-1'></span>
                    <span aria-hidden="true" data-icon="&#xe903;" className='f-contact-icon-1'></span>
                </div>
            </div>

        </div>
    );
}

export default Footer;