import React, { useEffect } from 'react';
import '../../App.css';
import '../NotFoundPage.css';

function NotFoundPage() {

    useEffect(() => {
        document.title = "404: Page not found | BaseTwo";  
    }, []);

    return (
        <>
            <div className="PageError">
                <h1>404</h1>

                <div>
                    <p>Page not found.</p>
                    <a href="/" >Back to Home →</a>
                </div>
            </div>
        </>
    );
}

export default NotFoundPage;