import React, { useEffect } from 'react';
import '../../App.css';
import  '../OurPortfolio.css';
import OurPortfolio02 from '../OurPortfolio02';
import FullpageWrapper from '../OurPortfolio03';
import ContactSection from '../ContactSection';
import MetaTags from './MetaTagsComponent';

function PortfolioPage() {

    useEffect(() => {
        document.title = "Our Portfolio | BaseTwo's Showcase of Projects ";  
    }, []);

    return (
        <> 
            <div className="portpage-mobile">
                <OurPortfolio02 />
                <ContactSection />
            </div>
                
            <div className="portpage-web">
                <FullpageWrapper />
            </div> 
            
            
            <MetaTags
                canonical={`https://base2.my/our-portfolio`} 
                ogurl={`https://base2.my/our-portfolio`} 

                // max 10-15 keywords
                keywords={`base two mobile app development projects, base two sdn bhd projects, base two sdn bhd portfolio, kuala lumpur it services, web and mobile development kl, base two web development, base two ui/ux design , e-commerce development, it solutions in klang valley, it company malaysia`
                }
            />
        </>
    ); 
}

export default PortfolioPage;