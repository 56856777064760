import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';


export const NavLink = styled(Link)`
  &.active {
    color: #ab295b;

    text-decoration: underline 2px solid #f985b2;
    text-underline-offset: 12px;
  }

  @media screen and (max-width: 576px)  {
    &.active { 
      text-decoration: none; 
      
    }
  }
`;

