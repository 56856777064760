import React, { useEffect } from 'react';
import '../../App.css';
import AboutB2 from '../AboutPage';
import MetaTags from './MetaTagsComponent';

function AboutUs() {

    useEffect(() => {
        document.title = "About Us | BaseTwo - Learn About Us and Our Mission";  
    }, []);

    return (
        <> 
            <AboutB2 />

            <MetaTags
                canonical={`https://base2.my/about-us`} 
                ogurl={`https://base2.my/about-us`} 

                // max 10-15 keywords
                keywords={`base two about us, base two sdn bhd company, about base 2, kuala lumpur it services, base two expertise, base two mission and vision, base two sdn bhd profile, malaysia it services, it solutions in klang valley, it solutions, kuala lumpur, malaysia, klang valley`
                }
            />
        </>
    ); 
}

export default AboutUs;