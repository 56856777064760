import React, { useEffect } from 'react';
import '../../App.css';
import UrbanWallPage from '../UrbanWallPage';
import MetaTags from './MetaTagsComponent';

function UrbanWall() {

    useEffect(() => {
        document.title = "Urban Wallstreet | BaseTwo";  
    }, []);

    return (
        <> 
            <UrbanWallPage />
            
            <MetaTags
                canonical={`https://base2.my/our-portfolio/urban-wall-street`} 
                ogurl={`https://base2.my/our-portfolio/urban-wall-street`} 

                // max 10-15 keywords
                keywords={`base two sdn bhd, base two IT services, base 2 sdn bhd, base 2, web and mobile development kl, base two web development, ui/ux design, e-commerce development, front-end development, back-end development, `
                }
            />
        </>
    ); 
}

export default UrbanWall;