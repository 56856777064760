import React, { useState } from 'react';
import { NavLink } from './NavbarElements';
import { Link } from 'react-router-dom';
import './Navbar.css';


const Navbar = () => {

    const b2_logo = require('../images/logo-color.png');

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    return (
        <>

            <div className="wrap-navbar">

                <div className="navbar-container">
                    <div>
                        <a href='/' className="navbar-logo"  target='_parent'> 
                            <img src={b2_logo} width="80px" alt="BaseTwo"/>
                        </a>
                    </div> 

                    <div className="navbar-content">
                        
                        <div className='menu-icon' onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>

                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <NavLink to='/' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                    Home
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to='/our-portfolio' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                    Our Portfolio
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to='/our-services' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                    Our Services
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to='/about-us' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                    About Us
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <a href='/#contactus' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                    Contact Us
                                </a>
                            </li>

                        </ul>
                    </div>
                </div> {/*  close navbar-container */}
                
            </div>
            
        </>

    )

};

export default Navbar;
