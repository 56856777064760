import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Portfolio from '../Portfolio';
// import OurPortfolio02 from '../OurPortfolio02';
import ServicesSection from '../ServicesSection';
import ContactSection from '../ContactSection';
import MetaTags from './MetaTagsComponent';


function Home() {
    return (
        <> 
            <MetaTags
                canonical={`https://base2.my/`} 
                ogurl={`https://base2.my/`} 

                // max 10-15 keywords
                keywords={`Base 2, Base2, Base Two, BaseTwo Sdn Bhd, Base Two Sdn Bhd, Base2 Sdn Bhd,Mobile App Developer, Software Developer, Solutions in Kuala Lumpur, IT Solutions Malaysia`}
            />
            <HeroSection />
            <Portfolio />
            {/* <OurPortfolio02 /> */}
            <ServicesSection />
            <ContactSection />
        </>
    ); 
}

export default Home;