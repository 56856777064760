import React from 'react';
import './ServicesSection.css';

function ServicesSectionProps(props) {
    return (
        <>
            <div className='services-items reveal'>
                <div className='services-item-1'> 
                    <h5 className='services-item-heading'>{props.heading}</h5>
                    <p className='services-item-cate'>{props.cate}</p>
                    <a href='/our-services' className='services-item-btn' >Know More </a>
                </div>

                <figure className='services-item-2' data-category={props.label}>
                    <img className='services-item-img'
                        alt='Base2 Projects'
                        src={props.img_1}
                    />
                </figure>
            </div>
        </>
    );
}


function ServicesSection() {

    const services_logo1 = require('../images/BG-05.png');
    const services_logo2 = require('../images/BG-06.png');
    const services_logo3 = require('../images/BG-07.png');
    const services_logo4 = require('../images/BG-08.png');
    const services_logo5 = require('../images/BG-10.png');


    return (
        
        <div className='wrap-services' >

            <div className='services-container'>
                <div className='services-header'>
                    <h2>Our Services</h2>
                </div>

                <div className='services-content-1'>
                    <div className='services-content-item'>
                        <ServicesSectionProps 
                            heading='iPhone App'
                            cate='Development'
                            img_1={services_logo1}
                        />
                        <ServicesSectionProps 
                            heading='Android App'
                            cate='Development'
                            img_1={services_logo2}
                        />
                        <ServicesSectionProps 
                            heading='iOS App'
                            cate='Development'
                            img_1={services_logo3}
                        />
                        <ServicesSectionProps 
                            heading='Cross Platform'
                            cate='Development'
                            img_1={services_logo4}
                        />
                        <ServicesSectionProps 
                            heading='Website & Web Application'
                            cate='Development'
                            img_1={services_logo5}
                        />
                    </div>
                    
                </div>
                
            </div>

            
        </div>
    );
}

export default ServicesSection;