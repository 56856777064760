import React from 'react';
import './UrbanWallPage.css';


function UrbanWallPage() {

    const urbanwall_demo1 = require('../images/BG-02.png'); 
    const urbanwall_demo2 = require('../images/BG-12.png');  
    const urbanwall_bg1 = require('../images/BG-13.jpg'); 
    const urbanwall_feature1 = require('../images/SS-01.png'); 
    const urbanwall_feature2 = require('../images/SS-03.png');
    const urbanwall_feature3 = require('../images/SS-02.png');
    const urbanwall_review1 = require('../images/BG-37.png');
    const urbanwall_review2 = require('../images/BG-37.png');
    const urbanwall_review3 = require('../images/BG-37.png');
    // const urbanwall_review3 = require('../images/UserReview-01.png');

    return (
        <div className='wrap-urbanwall'>

            <div className='urb-hero-container'>
                <div className='urb-hero-content'>
                    <div className='urb-hero-item-1'>
                        <h1>Urban Wallstreet</h1>
                        <p>Shop the latest streetwear items online at Urban Wallstreet and get your favorite on-trend items at your bit price! </p>
                    </div>
                    <div className='urb-hero-item-2'>
                        <img src={urbanwall_demo1} alt='Urban Wallstreet Project' />
                    </div>
                </div>
            </div>
            
            <div className='urb-body-container'>
                <div className='urb-body-content-1'>
                    
                    {/* <div className='urb-body-item-1'>
                        <div className='urb-body-itemcard'>
                            <h2>Idea</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='urb-body-itemcard'>
                            <h2>Challenge</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='urb-body-itemcard'>
                            <h2>Results</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                    </div> */}

                    <div className='urb-body-item-2'>
                        <img src={urbanwall_demo2} alt='Urban Wallstreet Project' />
                    </div>

                </div>

                <div className='urb-body-content-2'>
                    <img src={urbanwall_bg1} alt='Urban Wallstreet Project' />
                </div>

                <div className='urb-body-content-3'> 
                    <div className='urb-design-header'>
                        <h2>Design</h2>
                        <p>With this project, we've put emphasis on functionality and the utility of the app. The interface should not be distracting yet still pleasing to the eye.</p>
                    </div>

                    <div className='urb-design-features'>
                        <h2>Some Awesome Features</h2>

                        <div className='urb-feature-content'>
                            <div className='urb-feature-item'>
                                <img src={urbanwall_feature1} alt='Urban Wallstreet Project' />
                                <h4>Wishlist</h4>
                                <p>Buy Your Favourite Items by select the item and the size of the item and check out proceed to payment</p>
                            </div>
                            <div className='urb-feature-item'>
                                <img src={urbanwall_feature2} alt='Urban Wallstreet Project' />
                                <h4>Place Bid</h4>
                                <p>Place Bid if the item you want is currently out of stock you can choose to place a higher bid</p>
                            </div>
                            <div className='urb-feature-item'>
                                <img src={urbanwall_feature3} alt='Urban Wallstreet Project' />
                                <h4>Installment Payment Service</h4>
                                <p><strong>Buy now, pay later! </strong></p>
                                <p>Urban Wallstreet has flexible payment options for you to choose from that allows you to breathe easy when making big purchases!</p>
                            </div>
                        </div>
                    </div>

                    <div className='urb-reviews' >
                        <h2>What others are saying …</h2>

                        <div className='urb-reviews-content'>
                            <div className='urb-reviews-item'>
                                <img src={urbanwall_review1} alt='Urban Wallstreet Project' />
                                <h4>User-friendly app</h4>
                                <p>“Easy to use and good shopping experience!”</p>
                            </div>
                            <div className='urb-reviews-item'>
                                <img src={urbanwall_review2} alt='Urban Wallstreet Project' />
                                <h4>LZJ</h4>
                                <p>“Downloaded this app today just to get Lee Zii Jia limited mask tomorrow!! Thanks Urban Wall Street!”</p>
                            </div>
                            <div className='urb-reviews-item'>
                            {/* className='urb-review-img-3' */}
                                <img src={urbanwall_review3} alt='Urban Wallstreet Project' />
                                <h4>Good features</h4>
                                <p>“I can buy now and pay later! This app has flexible payment options for me to choose from that allows me to breathe easy when making the big purchases. Good!”</p>
                            </div>
                        </div>
                    </div>

                    <div className='urb-downloadapp' >
                        <h2>App Download</h2>

                        <div className='urb-downloadapp-content'>
                            <div className='urb-downloadapp-item'>
                                <p>This application is now available for download! Experience these all these features from now!</p>
                            </div>
                            <div className='urb-downloadapp-btn'>
                                <a href='https://apps.apple.com/us/app/urban-wallstreet-uws/id1550277206' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe902;" className='download-icon-1'></span>
                                        <p>iOS Download</p>
                                    </button>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.urbanwallstreet.app' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe901;" className='download-icon-1'></span>
                                        <p>Android Download</p>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UrbanWallPage;