import React, { useEffect } from 'react';

const MetaTags = ({ canonical, keywords, ogurl }) => {
  useEffect(() => {
    const head = document.head;

    // Remove existing meta tags
    // const existingMetaTags = head.querySelectorAll('meta[name="description"], meta[name="keywords"]');
    // existingMetaTags.forEach((tag) => tag.parentNode.removeChild(tag));

    // Create new meta tags
    const metaCanonical = document.createElement('meta');
    metaCanonical.setAttribute('name', 'canonical');
    metaCanonical.setAttribute('content', canonical);
    head.appendChild(metaCanonical);

    const metaKeywords = document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', keywords);
    head.appendChild(metaKeywords);

    const metaOGURL = document.createElement('meta');
    metaOGURL.setAttribute('property', 'og:url');
    metaOGURL.setAttribute('content', ogurl);
    head.appendChild(metaOGURL);

  }, [canonical, keywords, ogurl]);

  return null;
};

export default MetaTags;
