import React from 'react';
import './TCLamShopPage.css';


function TCLamShopPage() {

    const tclam_logo = require('../images/TCLam-00.png'); 
    const tclam_demo1 = require('../images/TCLam-02.png'); 
    const tclam_demo2 = require('../images/TCLam-01.png');  
    const tclam_bg1 = require('../images/TCLam-03.png'); 
    const tclam_feature1 = require('../images/TCLam-04.jpg'); 
    const tclam_feature2 = require('../images/TCLam-05.jpg');
    const tclam_feature3 = require('../images/TCLam-06.jpg');
    const tclam_review1 = require('../images/BG-37.png');
    const tclam_review2 = require('../images/BG-37.png');
    const tclam_review3 = require('../images/BG-37.png');
    // const tclam_review3 = require('../images/UserReview-01.png');

    return (
        <div className='wrap-tclam'>

            <div className='tclam-hero-container'>
                <div className='tclam-hero-content'>
                    <div className='tclam-hero-item-1'>
                        <img src={tclam_logo} className='tclam-hero-logo' alt="TCLam Project"/>
                        <h1>TC Lam Fruit Shop</h1>
                        <p>Craving for premium imported fruits? </p>
                        <p>TC Lam Fruit Shop got your covered! </p>
                        <p>Sit back, relax, and just order them through our apps and we will get it delivered to your doorsteps within 24 hours.</p>
                    </div>
                    <div className='tclam-hero-item-2'>
                        <img src={tclam_demo1} alt='TCLam Project' />
                    </div>
                </div>
            </div>
            
            <div className='tclam-body-container'>
                <div className='tclam-body-content-1'>
                    
                    {/* <div className='tclam-body-item-1'>
                        <div className='tclam-body-itemcard'>
                            <h2>Idea</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='tclam-body-itemcard'>
                            <h2>Challenge</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                        <div className='tclam-body-itemcard'>
                            <h2>Results</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                        </div>
                    </div> */}
                    <div className='tclam-body-item-1'>
                        <div className='tclam-body-itemcard'>
                            <h2>Flutter - App Development Toolkit</h2>
                            <p>Flutter Build iOS and Android apps using just one programming language (Dart)Build a portfolio of beautiful Flutter apps to impress any recruiter.</p>
                        </div>
                        <div className='tclam-body-itemcard'>
                            <h2>Quality Assurance</h2>
                            <p>Our team on the project will test all aspects of an app while it is still in the software development phase. QA engineers work in tandem with app developers so that any bugs located can be fixed right away.</p>
                        </div>
                    </div>
                    <div className='tclam-body-item-2'>
                        <img src={tclam_demo2} alt='TCLam Project' />
                    </div>

                </div>

                <div className='tclam-body-content-2'>
                    <img src={tclam_bg1} alt='TCLam Project' />
                </div>

                <div className='tclam-body-content-3'> 
                    <div className='tclam-design-header'>
                        <h2>Design</h2>
                        <p>With this project, we've put emphasis on functionality and the utility of the app. The interface should not be distracting yet still pleasing to the eye. </p>
                    </div>

                    <div className='tclam-design-features'>
                        <h2>Some Awesome Features</h2>

                        <div className='tclam-feature-content'>
                            <div className='tclam-feature-item'>
                                <img src={tclam_feature2} alt='TCLam Project' />
                                <h4>Fruits Filter</h4>
                                <p>Request any fruits with seasonal, limited, imported, and we will try our best to deliver for you.</p>
                            </div>
                            <div className='tclam-feature-item'>
                                <img src={tclam_feature1} alt='TCLam Project' />
                                <h4>Pre-order services</h4>
                                <p>Pre-order premium imported fruits by today and get the fruits within 7 to 10 days with reasonable price.</p>
                            </div>
                            <div className='tclam-feature-item'>
                                <img src={tclam_feature3} alt='TCLam Project' />
                                <h4>Guarantee good services</h4>
                                <p>If you received overripe fruits, broken or not in the good taste, we will provide fully refund service or exchange the new and fresh fruits to you.</p>
                            </div>
                        </div>
                    </div>

                    <div className='tclam-reviews' >
                        <h2>User Reviews</h2>

                        <div className='tclam-reviews-content'>
                            <div className='tclam-reviews-item'>
                                <img src={tclam_review1} alt='TCLam Project' />
                                <h4>Get your desired fruits!</h4>
                                <p>“I may requested any fruit I wanted and they will be searching for me. Even is any limited or hard to find of fruits, they also allow us to request from the app.”</p>
                            </div>
                            <div className='tclam-reviews-item'>
                                <img src={tclam_review2} alt='TCLam Project' />
                                <h4>Convenient to use</h4>
                                <p>“I like the pre-order fruits service, I can order the imported fruits what I want from the app and get is within 7 to 10 days with a really reasonable price!!! I love this app so much.”</p>
                            </div>
                            <div className='tclam-reviews-item'>
                                <img src={tclam_review3} alt='TCLam Project' />
                                <h4>Awesome pre-order services</h4>
                                <p>"I had received overripe fruits, and the fruits were not in the good taste, then I try to contact the seller and they were providing me to chose either fully refund or exchange the new fruits. The services from them are really good and I will purchase fruits from TC Lam Fruit Shop again.”</p>
                            </div>
                        </div>
                    </div>

                    <div className='tclam-downloadapp' >
                        <h2>App Download</h2>

                        <div className='tclam-downloadapp-content'>
                            <div className='tclam-downloadapp-item'>
                                <p>This application is now available for download! Experience these all these features from now!</p>
                            </div>
                            <div className='tclam-downloadapp-btn'>
                                <a href='https://apps.apple.com/my/app/tc-lam-shop/id1598071768' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe902;" className='download-icon-1'></span>
                                        <p>iOS Download</p>
                                    </button>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.tclam.fruits' target="_blank" rel="noreferrer">
                                    <button>
                                        <span aria-hidden="true" data-icon="&#xe901;" className='download-icon-1'></span>
                                        <p>Android Download</p>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TCLamShopPage;