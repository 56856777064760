import React from 'react';
import './Portfolio.css';
import PortfolioItem from './PortfolioItem';

// import { Animator, ScrollContainer, ScrollPage, 
//     batch, Fade, FadeIn, Move, MoveIn, MoveOut, 
//     Sticky, StickyIn, ZoomIn } from "react-scroll-motion";

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    
    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;
    
        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}
    
window.addEventListener("scroll", reveal);
// reveal();

function Portfolio() {
    
    // npm animation {react-scroll-motion}
    // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
    // const FadeUp = batch(Fade(), Move(), Sticky());
    
    const portfolio_logo1 = require('../images/logo-color.png');
    const portfolio_logo2 = require('../images/logo-white.png');
    const portfolio_img1 = require('../images/BG-02.png');
    const portfolio_img2 = require('../images/TCLam-02.png');
    const portfolio_img3 = require('../images/WashLa-05.png');



    return (
        
        <div className='wrap-portfolio'>

            <div className='portfolio-header'>
                <h2>Our Portfolio</h2>
            </div>

            {/* <ScrollContainer className='portfolio-container'> 
                <ScrollPage page={1} id="scrollpage">
                    <Animator animation={FadeUp}> */}
                        <div className='portfolio-content-1'>
                            <PortfolioItem 
                                img_1={portfolio_logo1}
                                heading='Urban Wallstreet'
                                img_2={portfolio_img1}
                                desc='Shop the latest streetwear items online at Urban Wallstreet and get your favorite on-trend items at your bit price!'
                                projectlink="/our-portfolio/urban-wall-street"
                            />
                        </div>
                    {/* </Animator>
                </ScrollPage>

                <ScrollPage page={2}>
                    <Animator animation={FadeUp}> */}
                        <div className='portfolio-content-2'>
                            <PortfolioItem 
                                img_1={portfolio_logo2}
                                heading='TC Lam Fruit Shop'
                                img_2={portfolio_img2}
                                desc="With over 5 years of experience in supplying top quality imported fruits, TC Lam Fruit Shop got you covered for that craving while you sit back and relax and just order through TC Lam Fruit Shop's mobile app available on Apple AppStore and Google PlayStore."
                                projectlink='/our-portfolio/tclamshop'
                            />
                        </div>
                    {/* </Animator>
                </ScrollPage>

                <ScrollPage page={3}>
                    <Animator animation={FadeUp}> */}
                        <div className='portfolio-content-3'>
                            <PortfolioItem 
                                img_1={portfolio_logo1}
                                heading='Wash La!'
                                img_2={portfolio_img3}
                                desc='Wash La! is a mobile app that provide picks up, cleans and delivers your sneakers, bags, caps, laundry and dry cleaning.'
                                projectlink='/our-portfolio/wash-la'
                            />
                        </div>
                    {/* </Animator>
                </ScrollPage>

            </ScrollContainer> */}

            <div className='portfolio-container-2'>
                <div className='portfolio-viewmore'>
                    <p>We work with our clients to deliver superb user-centric solutions while ensuring their business objectives are met.</p>
                    <a href="/our-portfolio"><button>View More Works</button></a>
            
                </div>
            </div>
        </div>
    );
}

export default Portfolio;