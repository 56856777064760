import React from 'react';
import { Link } from 'react-router-dom';



function PortfolioItem(props) {
    

    return (
        <>
            <div className='portfolio-items reveal '>
                <div className='portfolio-item-1'>
                    <img className='portfolio-item-img'
                        alt='Base2 Projects'
                        src={props.img_1}
                    />
                    <h5 className='portfolio-item-heading'>{props.heading}</h5>
                </div>

                <figure className='portfolio-item-2' data-category={props.label} >
                    <img className='portfolio-item-img'
                        alt='Base2 Projects'
                        src={props.img_2}
                    />
                </figure>
                
                <div className='portfolio-item-3'>
                    <p className='portfolio-item-text'>{props.desc}</p>
                    {/* <a href='/{props.projectlink}' className='portfolio-item-btn'>View Project</a> */}
                    <a href={props.projectlink}  className='portfolio-item-btn' target='_parent'>View Project</a>
                
                </div>
            </div>
        </>
    );
}

export default PortfolioItem;