import React from 'react';
import '../App.css';
import './HeroSection.css';
// import { Link } from 'react-router-dom';


function HeroSection() {
    
    return (
        
        <div className='wrap-hero'>

            <div className='hero-container'> 
                <div className='hero-heading'>
                    <h1>Turn Your Idea into Reality with Base Two</h1>
                </div>

                <div className='hero-desc'>
                    <p>We are web and mobile app development expert based in Malaysia. We partner with our clients to turn their ideas into outstanding products, and bring them to market with the right strategy.</p>
                </div>

                <div className='hero-btns'>
                    <a href="/#contactus"><button>Contact Us</button></a>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;