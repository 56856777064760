import React, { useEffect } from 'react';
import '../../App.css';
import Services from '../Services';
import ContactSection from '../ContactSection';
import MetaTags from './MetaTagsComponent';

function ServicesPage() {

    useEffect(() => {
        document.title = "Our Services | BaseTwo - Expertise and Solutions";  
    }, []);

    return (
        <> 
            <Services />
            <ContactSection />
            
            <MetaTags
                canonical={`https://base2.my/our-services`} 
                ogurl={`https://base2.my/our-services`} 

                // max 10-15 keywords
                keywords={`base two sdn bhd, base two services, base two sdn bhd services, base 2, web and mobile development kl, base two web development, ui/ux design, e-commerce development, front-end development klang valley, it company kuala lumpur`
                }
            />
        </>
    ); 
}

export default ServicesPage;