import React from 'react';
import './Portfolio.css';
import './OurPortfolio.css';
import './PortfolioTesting.css';
import  Carousel from 'react-bootstrap/Carousel';
import './portfolio-sass.scss';



function OurPortfolio02() {
    

    // const ourport_logo1 = require('../images/logo-color.png');
    // const ourport_logo2 = require('../images/logo-white.png');
    const ourport_img1 = require('../images/BG-02.png');
    const ourport_img2 = require('../images/TCLam-02.png');
    const ourport_img3 = require('../images/WashLa-05.png');
    const ourport_img4 = require('../images/AKC-01.png');
    const ourport_img5 = require('../images/UDBio-01.png');

    // const urban_logo = require('../images/Urban-00.png');
    // const tclam_logo = require('../images/TCLam-07.png');
    // const washla_logo = require('../images/WashLa-08.png');

    return (
        <>
        <div className='wrap-ourport-02'>

            <Carousel variant="dark">
                <Carousel.Item className="portfolio-caro-item">
                    <div className="portfolio-caro-img-cont">
                        <img
                            className="d-block port-carol-img-1"
                            src={ourport_img1}
                            alt="Urban Wallstreet"
                        />
                    </div>
                    
                    <Carousel.Caption>
                    {/* <img src={urban_logo} className="proj-logo-01" alt="Urban Wallstreet" /> <br/> */}
                        <h5>Urban Wallstreet</h5>
                        <p>Urban Wallstreet is an online collectable trading marketplace that aims to smoothen every step of your transaction, whether you are a buyer or a seller! Shop the latest and greatest in streetwear at UR and bid for your favourite on-trend items!

                        </p>
                        
                        <a href="/our-portfolio/urban-wall-street"  className='mb-port-btn-1' target='_blank' rel="noreferrer noopener">View Project</a>
                        
                        <a href="/our-portfolio/urban-wall-street" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="gooey-button">
                                View Project
                                <span class="urban-1">
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                    <span class="urban-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="portfolio-caro-item">
                    <div className="portfolio-caro-img-cont">
                        <img
                            className="d-block port-carol-img-2"
                            src={ourport_img2}
                            alt="TC Lam Fruit Shop"
                        />
                    </div>
                    
                    <Carousel.Caption>
                    {/* <img src={tclam_logo} className="proj-logo-01" alt="TC Lam Fruit Shop" /> <br/> */}
                        <h5>TC Lam Fruit Shop</h5>
                        <p>With over 5 years of experience in supplying top quality imported fruits, TC Lam Fruit Shop got you covered for that craving while you sit back and relax and just order through TC Lam Fruit Shop's mobile app available on Apple AppStore and Google PlayStore.</p>
                    
                        <a href="/our-portfolio/tclamshop"  className='mb-port-btn-2' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="/our-portfolio/tclamshop" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="tclam-button">
                                View Project
                                <span class="tclam-1">
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                    <span class="tclam-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="portfolio-caro-item">
                    <div className="portfolio-caro-img-cont">
                        <img
                            className="d-block port-carol-img-3"
                            src={ourport_img3}
                            alt="Wash La!"
                        /> 
                    </div>
                   
                    <Carousel.Caption>
                        {/* <img src={washla_logo} className="proj-logo-01" alt="Wash La" /> <br/> */}
                        <h5> Wash La!</h5>
                        <p>Wash La! is a shoe wash & sneaker cleaning service provider with door-to-door pick-up and delivery service anywhere within Klang Valley mobile app (available on iOS and Android). You can just sit back, relax and book a pick-up and delivery from the comfort of your home, at your choice of place and time.</p>
                
                        <a href="/our-portfolio/wash-la"  className='mb-port-btn-3' target='_blank' rel="noreferrer noopener">View Project</a>

                        <a href="/our-portfolio/wash-la" className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        {/* <!-- in="sourceGraphic" --> */}
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="washla-button">
                                View Project
                                <span class="washla-1">
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                    <span class="washla-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="portfolio-caro-item">
                    <div className="portfolio-caro-img-cont">
                        <img
                            className="d-block port-carol-img-4"
                            src={ourport_img4}
                            alt="Ape Kids Club Web Development"
                        /> 
                    </div>
                    
                    <Carousel.Caption>
                        <h5 className="port-caro-head-2">Ape Kids Club Web Development</h5>
                        <p>AKC has 9,999 NFTs Ape kids by BAYC 4928 and BAYC 6268 created by love! NFTs are an exciting new way of digital ownership, we create unique experiences for our clients by offering affordable, high-quality web design and website development.</p>
                        
                        <a href="https://www.apekidsclub.io/"  className='mb-port-btn-4' target='_blank' rel="noreferrer noopener">View Project</a>
                        
                        <a href="https://www.apekidsclub.io/"  className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="akc-button">
                                View Project
                                <span class="akc-1">
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                    <span class="akc-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="portfolio-caro-item">
                    <div className="portfolio-caro-img-cont">
                        <img
                            className="d-block port-carol-img-4"
                            src={ourport_img5}
                            alt="UD-Bio Malaysia"
                        />
                    </div>
                    
                    <Carousel.Caption>
                        <h5 className="port-caro-head-2">UD-Bio Web Development</h5>
                        <p>Shenzhen UD-Bio was founded in June 2016 and is headquartered in Shenzhen. It is a Shenzhen and National high-tech company engaged in the research, development, production, sales and services of IVD instruments and reagents.</p>
                        
                        <a href="https://ud-bio.com.my/"  className='mb-port-btn-5' target='_blank' rel="noreferrer noopener">View Project</a>
                        
                        <a href="https://ud-bio.com.my/"  className='portfolio-caro-item-btn-1' target='_blank' rel="noreferrer noopener">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                    <filter id="gooey">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
                                        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
                                    </filter>
                                </defs>
                            </svg>
                            <button id="udbio-button">
                                View Project
                                <span class="udbio-1">
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                    <span class="udbio-1-2"></span>
                                </span>
                            </button>
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>


            </Carousel>


        </div>

        </>
    );
}

export default OurPortfolio02;